var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviews"},[_c('div',{staticClass:"reviews__panel-group"},[_c('div',{staticClass:"reviews__panels"},[(_vm.isBorderedSnapshots)?_c('div',{staticClass:"reviews__heading"},[_c('VfHeading',{attrs:{"title":_vm.translations.heading,"title-modifier":"title-3","level":"2"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reviews__snapshot reviews__snapshot--background",class:{
          'reviews--bordered': _vm.isBorderedSnapshots,
        }},_vm._l((_vm.snapshotOrderedList),function(section,index){return _c('div',{key:index},[(section === 'reviews')?[(!_vm.isBorderedSnapshots)?_c('VfHeading',{class:{ reviews__title: _vm.isCoreRedesignEnabled },attrs:{"title":_vm.translations.heading,"title-modifier":_vm.isCoreRedesignEnabled ? 'title-4' : 'title-2',"level":"2"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reviews__snapshot-bottom",class:{
                'reviews__snapshot-bottom--redesign': _vm.isCoreRedesignEnabled,
              }},[_c('VfRating',{attrs:{"show-aria-label":false,"score":_vm.review.score,"has-indicator":_vm.showStarsIndicator}}),_vm._v(" "),_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v("\n                "+_vm._s(_vm.review.reviewsCount === 1
                    ? ("" + (_vm.translations.reviewsCount.singular.replace(
                        '{0}',
                        _vm.review.reviewsCount
                      )))
                    : ("" + (_vm.translations.reviewsCount.plural.replace(
                        '{0}',
                        _vm.review.reviewsCount
                      ))))+"\n              ")]),_vm._v(" "),(_vm.showWriteReviewCta)?_c(_vm.isBorderedSnapshots ? 'ThemeButton' : 'ThemeLink',{tag:"component",staticClass:"reviews__add-review-button",class:{
                  'reviews__add-review-button--redesign': _vm.isCoreRedesignEnabled,
                },attrs:{"color":"primary","size":_vm.isCoreRedesignEnabled ? 'sm' : 'md',"variant":"border","legacy":false},on:{"click":function($event){return _vm.$emit('click:write-review')}}},[_vm._v("\n                "+_vm._s(_vm.translations.writeReview)+"\n              ")]):_vm._e()],1)]:(_vm.review.recommendationsCount)?_c('div',{staticClass:"reviews__snapshot-recommendations"},[_c('div',{staticClass:"reviews__snapshot-recommendations--top"},[(_vm.showSnapshotIcon)?_c('VfIcon',{attrs:{"icon":"correct"}}):_vm._e(),_vm._v(" "),_c('VfText',{attrs:{"modifier":"title-4"}},[_vm._v("\n                "+_vm._s(((Math.ceil(_vm.review.recommendationsCount)) + " %"))+"\n              ")])],1),_vm._v(" "),_c('VfText',{staticClass:"reviews__snapshot-recommendations--bottom"},[_vm._v("\n              "+_vm._s(_vm.translations.recommendationsLabel.replace(
                  '{0}',
                  ((Math.ceil(_vm.review.recommendationsCount)) + "%")
                ))+"\n            ")])],1):_vm._e()],2)}),0),_vm._v(" "),_c('VfRow',{staticClass:"reviews__panel reviews__panel--background",class:{
          'reviews--bordered': _vm.isBorderedSnapshots,
        }},[_c('VfColumn',{attrs:{"small":"12","medium":_vm.isBorderedSnapshots ? 12 : 4,"large":_vm.isBorderedSnapshots ? 12 : 4}},[_c('div',{staticClass:"reviews__section-rating"},[_c('VfHeading',{staticClass:"reviews__section-heading",class:{
                'reviews__section-heading--redesign-core': _vm.isCoreRedesignEnabled,
              },attrs:{"level":3,"title":_vm.translations.ratingDistribution.heading}}),_vm._v(" "),_vm._l((_vm.review.ratingDistribution),function(rating){return _c('VfHistogram',{key:rating.stars,attrs:{"value":rating.value,"percentage-value":_vm.getRatingValue(rating.value, _vm.review.ratingDistribution),"label":("" + (_vm.translations.ratingDistribution.starsCount.replace(
                '{0}',
                rating.stars
              ))),"tooltip":_vm.translations.ratingDistribution.tooltip.replace(
                  '{0}',
                  rating.stars
                ),"is-selected":!!_vm.review.filters.find(
                  function (f) { return f.key === 'rating' && f.value.includes(rating.stars); }
                )},on:{"click:add":function($event){return _vm.addFilter('rating', rating.stars)},"click:remove":function($event){return _vm.$emit('click:remove-filter', {
                  key: 'rating',
                  value: [rating.stars],
                })}}})})],2)]),_vm._v(" "),(!_vm.isBorderedSnapshots)?_c('VfColumn',{attrs:{"small":"12","medium":4,"large":4}},[_c('VfProCon',{attrs:{"translations":_vm.translations.tags,"heading":_vm.translations.pros.heading,"empty-text":_vm.translations.pros.emptyText,"tooltip":_vm.translations.proConTagTooltip,"items":_vm.review.pros,"reduced-list-count":_vm.reducedListCount},on:{"click:add":function (value) { return _vm.addFilter('pros', value); },"click:remove":function (value) { return _vm.$emit('click:remove-filter', { key: 'pros', value: [value] }); }}})],1):_vm._e(),_vm._v(" "),(!_vm.isBorderedSnapshots)?_c('VfColumn',{attrs:{"small":"12","medium":4,"large":4}},[_c('VfProCon',{attrs:{"translations":_vm.translations.tags,"heading":_vm.translations.cons.heading,"empty-text":_vm.translations.cons.emptyText,"tooltip":_vm.translations.proConTagTooltip,"items":_vm.review.cons,"reduced-list-count":_vm.reducedListCount},on:{"click:add":function (value) { return _vm.addFilter('cons', value); },"click:remove":function (value) { return _vm.$emit('click:remove-filter', { key: 'cons', value: [value] }); }}})],1):_vm._e()],1),_vm._v(" "),(
          _vm.showSizeAndWidthChart && (_vm.hasTagLabel(_vm.sizing) || _vm.hasTagLabel(_vm.width))
        )?_c('VfRow',{staticClass:"reviews__panel reviews__panel--background",class:{
          'reviews--bordered': _vm.isBorderedSnapshots,
        }},[_c('VfColumn',{attrs:{"small":"12","medium":_vm.isBorderedSnapshots ? 12 : 4,"large":_vm.isBorderedSnapshots ? 12 : 4}},[_c('VfHeading',{class:{
              'reviews__section-heading': true,
              'reviews__section-heading--redesign-core': _vm.isCoreRedesignEnabled,
            },attrs:{"level":3,"title":_vm.sizing.heading}}),_vm._v(" "),_c('div',{staticClass:"reviews__section-fit"},[(_vm.chestsize && _vm.chestsize.heading)?_c('VfFitScale',{attrs:{"same-line-labels":_vm.sameLineLabels,"heading":_vm.chestsize.heading,"value":_vm.chestsize.value,"min-label":_vm.chestsize.minLabel,"max-label":_vm.chestsize.maxLabel}}):_vm._e(),_vm._v(" "),(_vm.sizing && _vm.sizing.heading)?_c('VfFitScale',{attrs:{"same-line-labels":_vm.sameLineLabels,"heading":_vm.sizing.heading,"value":_vm.sizing.value.value,"max-value":_vm.sizing.value.maxValue,"min-label":_vm.sizing.minLabel,"max-label":_vm.sizing.maxLabel}}):_vm._e(),_vm._v(" "),(_vm.sleevelength && _vm.sleevelength.heading)?_c('VfFitScale',{attrs:{"same-line-labels":_vm.sameLineLabels,"heading":_vm.sleevelength.heading,"value":_vm.sleevelength.value,"min-label":_vm.sleevelength.minLabel,"max-label":_vm.sleevelength.maxLabel}}):_vm._e(),_vm._v(" "),(_vm.width.value)?_c('VfFitScale',{attrs:{"same-line-labels":_vm.sameLineLabels,"heading":_vm.width.heading,"value":_vm.width.value,"min-label":_vm.width.minLabel,"max-label":_vm.width.maxLabel}}):_vm._e()],1)],1)],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"reviews__tag-group"},[_vm._l((_vm.review.tags),function(tagsSection){return _c('VfRow',{key:tagsSection.heading,staticClass:"reviews__tags-row"},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","large-margins":{ custom: '2px 12px' }}},[_c('div',{staticClass:"reviews__tags"},[_c('VfHeading',{class:{
              'reviews__section-heading': true,
              'reviews__section-heading--redesign-core': _vm.isCoreRedesignEnabled,
            },attrs:{"level":3,"title":tagsSection.heading}}),_vm._v(" "),_c('VfTagGroup',{attrs:{"translations":_vm.translations.tags,"tags":tagsSection.tags},on:{"click:add":function (value) { return _vm.addFilter(tagsSection.key, value); },"click:remove":function (value) { return _vm.$emit('click:remove-filter', {
                  key: tagsSection.key,
                  value: [value],
                }); }}})],1)])],1)}),_vm._v(" "),(_vm.showMostLikedReviews)?_c('VfRow',{staticClass:"reviews__most-liked-row"},[(_vm.review.mostLikedPositive)?_c('VfColumn',{staticClass:"reviews__most-liked-positive",attrs:{"small":"12","medium":"6","large":"6"}},[_c('VfMostLikedReview',{attrs:{"heading":_vm.translations.mostLikedPositiveHeading,"review":_vm.review.mostLikedPositive,"read-complete-text":_vm.translations.mostLikedReadComplete,"read-less-text":_vm.translations.mostLikedReadLess}})],1):_vm._e(),_vm._v(" "),(_vm.review.mostLikedNegative)?_c('VfColumn',{staticClass:"reviews__most-liked-negative",attrs:{"small":"12","medium":"6","large":"6"}},[_c('div',{staticClass:"reviews__most-liked-negative-corner"}),_vm._v(" "),_c('VfMostLikedReview',{attrs:{"heading":_vm.translations.mostLikedNegativeHeading,"review":_vm.review.mostLikedNegative,"read-complete-text":_vm.translations.mostLikedReadComplete,"read-less-text":_vm.translations.mostLikedReadLess}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reviews__list-top-bar",class:{ reviews__title: _vm.isCoreRedesignEnabled },attrs:{"id":"reviews__list-top-bar"}},[_c('VfText',{staticClass:"vf-heading__title vf-text",class:[
          _vm.isCoreRedesignEnabled ? 'vf-text--title-4' : 'vf-text--title-2' ],attrs:{"content":_vm.reviewListHeading,"html":true,"has-parent-tag":"","tag":"h2","modifier":"title-2"}}),_vm._v(" "),_c('VfSelect',{attrs:{"label":_vm.translations.list.sortingAriaLabel,"value":_vm.review.sorting.sortBy},on:{"selected":function (value) { return _vm.$emit('change:sorting', value); }}},_vm._l((_vm.translations.sortingOptions),function(value,key){return _c('VfSelectOption',{key:key,attrs:{"value":key}},[_vm._v("\n          "+_vm._s(value)+"\n        ")])}),1)],1),_vm._v(" "),(_vm.review.filters.length)?_c('div',{staticClass:"reviews__list-filters-applied"},[_c('VfHeading',{attrs:{"title":((_vm.translations.list.filtersApplied) + ":"),"title-modifier":"title-4","level":"4"}}),_vm._v(" "),_c('div',{staticClass:"reviews__list-filters-applied-list"},_vm._l((_vm.review.filters),function(filter){return _c('div',{key:filter.key},_vm._l((filter.value),function(tag){return _c('VfTag',{key:filter.key + '_' + tag,attrs:{"label":filter.key === 'rating'
                ? tag === 1
                  ? ("" + (_vm.translations.ratingDistribution.starCount.replace(
                      '{0}',
                      tag
                    )))
                  : ("" + (_vm.translations.ratingDistribution.starsCount.replace(
                      '{0}',
                      tag
                    )))
                : tag,"counter":_vm.getAppliedFilterCounter(filter.key, tag),"is-selected":false},on:{"click":function($event){return _vm.$emit('click:remove-filter', { key: filter.key, value: [tag] })}}},[_c('VfIcon',{attrs:{"icon":"cross","size":"0.7rem"}})],1)}),1)}),0)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reviews__list"},_vm._l((_vm.review.reviews),function(singleReview){return _c('div',{key:singleReview.reviewId,staticClass:"reviews__list-item"},[_c('VfRow',[_c('VfColumn',{attrs:{"small":"12","medium":"8","large":"8"}},[_c('VfRating',{attrs:{"score":singleReview.score,"has-indicator":_vm.showStarsIndicator}}),_vm._v(" "),_c('VfHeading',{staticClass:"reviews__list-item-title",class:{
                'reviews__list-item-title--redesign': _vm.isCoreRedesignEnabled,
              },attrs:{"title":singleReview.title,"title-modifier":"title-4","level":"3"}}),_vm._v(" "),(!_vm.showSubmittedByBeforeDesc)?_c('VfText',{class:{
                'reviews__list-item-text--redesign': _vm.isCoreRedesignEnabled,
              },attrs:{"font-sizes":['sm']}},[_vm._v(_vm._s(singleReview.description))]):_vm._e(),_vm._v(" "),_c('VfReviewSubmittedBy',{staticClass:"small-only",attrs:{"date":singleReview.details.createdDate,"buyer":singleReview.details.nickname,"from":singleReview.details.location,"is-verified-buyer":singleReview.badges.isVerifiedBuyer,"submitted-label":_vm.translations.submittedBy.submittedLabel,"submitted-by-label":_vm.translations.submittedBy.submittedByLabel,"from-label":_vm.translations.submittedBy.fromLabel,"verified-buyer-label":_vm.translations.submittedBy.verifiedBuyerLabel,"disclosure-label":_vm.getDisclosureLabel(singleReview.details.disclosureCode)}}),_vm._v(" "),(_vm.showSubmittedByBeforeDesc)?_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v(_vm._s(singleReview.description))]):_vm._e(),_vm._v(" "),(singleReview.merchantResponse)?_c('div',{staticClass:"reviews__list-item-merchant-response"},[_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v(_vm._s(_vm.translations.list.merchantResponse))]),_vm._v(" "),_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v(_vm._s(singleReview.merchantResponse))])],1):_vm._e(),_vm._v(" "),(singleReview.media && singleReview.media.length)?_c('div',{staticClass:"reviews__list-item-ugc"},[_c('VfReviewUgc',{attrs:{"review-title":singleReview.title,"rating":singleReview.score,"media":singleReview.media,"translations":_vm.translations.flagReviewForm},on:{"click:helpful-thumb-up":function (value) { return _vm.$emit('click:helpful-thumb-up', {
                      reviewId: singleReview.reviewId,
                      mediaId: value,
                    }); },"click:helpful-thumb-down":function (value) { return _vm.$emit('click:helpful-thumb-down', {
                      reviewId: singleReview.reviewId,
                      mediaId: value,
                    }); },"flag-review-submit":function (value) { return _vm.$emit('flag-review-submit', Object.assign({}, value,
                      {reviewId: singleReview.reviewId})); }}})],1):_vm._e(),_vm._v(" "),(singleReview.properties && singleReview.properties.length)?_c('div',{staticClass:"reviews__list-item-review-categories"},[_c('VfReviewCategories',{attrs:{"review-properties":singleReview.properties,"translations":_vm.translations.reviewCategories}})],1):_vm._e(),_vm._v(" "),(singleReview.bottomLine)?_c('div',{staticClass:"reviews__list-item-bottom-line"},[_c('VfHeading',{attrs:{"title":_vm.translations.list.bottomLine + ':',"title-modifier":"title-5","level":"4"}}),_vm._v(" "),_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v("\n                "+_vm._s(_vm.getReviewBottomLine(singleReview.bottomLine))+"\n              ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reviews__list-item-helpful-line"},[_c('VfHeading',{attrs:{"title":_vm.translations.list.helpfulLine,"title-modifier":"title-5","level":"4"}}),_vm._v(" "),_c('div',{staticClass:"reviews__list-item-helpful-line-inner"},[_c('VfHelpful',{attrs:{"thumb-up-count":singleReview.thumbUpCount,"thumb-down-count":singleReview.thumbDownCount,"is-up-selected":singleReview.isUpSelected,"is-down-selected":singleReview.isDownSelected,"aria-label-helpful":_vm.translations.flagReviewForm.helpfulVoteLabel.replace(
                      '{0}',
                      singleReview.thumbUpCount
                    ),"aria-label-unhelpful":_vm.translations.flagReviewForm.unhelpfulVoteLabel.replace(
                      '{0}',
                      singleReview.thumbDownCount
                    )},on:{"helpful-thumb-up":function($event){return _vm.$emit('click:helpful-thumb-up', singleReview)},"helpful-thumb-down":function($event){return _vm.$emit('click:helpful-thumb-down', singleReview)}}}),_vm._v(" "),_c('VfFlagReview',{attrs:{"translations":_vm.translations.flagReviewForm,"review-id":singleReview.reviewId,"flagged":singleReview.flagged},on:{"flag-review-submit-reviews":function (value) {
                      _vm.$emit('flag-review-submit', value);
                    }}})],1)],1)],1),_vm._v(" "),_c('VfColumn',{staticClass:"reviews__list-item-submitted-by-container large-only",attrs:{"small":"12","medium":"4","large":"4"}},[_c('VfReviewSubmittedBy',{attrs:{"date":singleReview.details.createdDate,"buyer":singleReview.details.nickname,"from":singleReview.details.location,"is-verified-buyer":singleReview.badges.isVerifiedBuyer,"submitted-label":_vm.translations.submittedBy.submittedLabel,"submitted-by-label":_vm.translations.submittedBy.submittedByLabel,"from-label":_vm.translations.submittedBy.fromLabel,"verified-buyer-label":_vm.translations.submittedBy.verifiedBuyerLabel,"disclosure-label":_vm.getDisclosureLabel(singleReview.details.disclosureCode)}})],1)],1)],1)}),0),_vm._v(" "),_c('div',{staticClass:"reviews__list-bottom-bar"},[(_vm.review.reviews && _vm.review.reviews.length)?[_c('div',{staticClass:"reviews__list-bottom-bar--count"},[_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v("\n            "+_vm._s(_vm.translations.list.displayingReviews)+"\n            "+_vm._s(_vm.paginationInfo)+"\n          ")]),_vm._v(" "),_c('ThemeLink',{attrs:{"color":"primary","size":"md","legacy":false},on:{"click":function($event){return _vm.scrollIntoView('#pr-container')}}},[_vm._v("\n            "+_vm._s(_vm.translations.list.backToTop)+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"reviews__list-bottom-bar--pagination"},[(_vm.review.pagination.currentPageNumber > 1)?_c('ThemeLink',{attrs:{"color":"primary","size":"md","legacy":false},on:{"click":function($event){_vm.$emit('click:previous-page'), _vm.scrollIntoView('#pr-container')}}},[(_vm.showPaginationIcons)?_c('VfIcon',{attrs:{"icon":_vm.paginationIconPrevious,"size":"1rem"}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.translations.list.prevPage)+"\n          ")],1):_vm._e(),_vm._v(" "),(_vm.showPaginationSeperator)?_c('span',{staticClass:"reviews__list-bottom-bar--seperator"}):_vm._e(),_vm._v(" "),(
              _vm.review.pagination.currentPageNumber <
              _vm.review.pagination.pagesTotal
            )?_c('ThemeLink',{attrs:{"color":"primary","size":"md","legacy":false},on:{"click":function($event){_vm.$emit('click:next-page'), _vm.scrollIntoView('#pr-container')}}},[_vm._v("\n            "+_vm._s(_vm.translations.list.nextPage)+"\n            "),(_vm.showPaginationIcons)?_c('VfIcon',{attrs:{"icon":_vm.paginationIconNext,"size":"1rem"}}):_vm._e()],1):_vm._e()],1)]:_c('div',{staticClass:"reviews__list-no-results"},[_c('VfText',{staticClass:"reviews__list-no-results-text",attrs:{"content":_vm.translations.list.noResults}})],1)],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }