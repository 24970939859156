








import {
  defineComponent,
  ref,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api';

export default defineComponent({
  name: 'DialogComponent',
  props: {
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const dialog = ref(null);

    const close = () => dialog.value.close();
    const open = () => dialog.value.showModal();

    const clickOutsideHandler = (e) => {
      const dialogDimensions = dialog.value.getBoundingClientRect();
      if (
        e.clientX < dialogDimensions.left ||
        e.clientX > dialogDimensions.right ||
        e.clientY < dialogDimensions.top ||
        e.clientY > dialogDimensions.bottom
      ) {
        close();
      }
    };

    onMounted(() => {
      if (props.closeOnClickOutside) {
        dialog.value.addEventListener('mouseup', clickOutsideHandler);
        dialog.value.addEventListener('touchup', clickOutsideHandler);
      }
    });

    onBeforeUnmount(() => {
      if (props.closeOnClickOutside) {
        dialog.value.removeEventListener('mouseup', clickOutsideHandler);
        dialog.value.removeEventListener('touchup', clickOutsideHandler);
      }
    });

    return {
      dialog,
      open,
      close,
    };
  },
});
