


















import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import ApplePay from '@/components/payment/ApplePay.vue';
import type { ApplePayTranslations } from '@vf/api-contract';
import {
  ApplePayContext,
  useApplePay,
  useApplePayPdpCart,
  useNotification,
  useProduct,
  useUpsell,
} from '@vf/composables';
import useLoader from '@/shared/useLoader';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ApplePayPdp',
  components: {
    ApplePay,
  },
  props: {
    translations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const { initApplePaySession, isApplePayApiDefined } = useApplePay(
      root,
      ApplePayContext.PDP
    );
    const { clearNotifications } = useNotification(root);
    const {
      product,
      checkAttributes,
      scrollToFirstValidationError,
      isSelectedProductOutOfStock,
    } = useProduct(root, props.contextKey);
    const { upsellItem, isUpsellSelected } = useUpsell(root, props.contextKey);
    const { resetCart } = useApplePayPdpCart(root);
    const { pdpShowApplePayAsPrimaryCta } = useFeatureFlagsStore();

    const validateAndAddToCart = () => {
      clearNotifications();
      if (checkAttributes()) {
        if (root.$themeConfig?.productAddToCart?.scrollToError) {
          const {
            scrollToErrorOffsetWithTopStickyHeader,
            scrollToErrorOffset: scrollToErrorOffsetTheme,
          } = root.$themeConfig.productAddToCart;
          // TODO: GLOBAL15-63801 clean up
          const scrollToErrorOffset = !root.$viewport.isSmall
            ? scrollToErrorOffsetWithTopStickyHeader
            : scrollToErrorOffsetTheme;
          scrollToFirstValidationError(scrollToErrorOffset);
        }
        return;
      }
      showSpinner();

      resetCart({ suppressLocalStorageOverride: true });

      const productsToBeAddedToCart = isUpsellSelected.value
        ? [product.value, upsellItem.value]
        : product.value;

      initApplePaySession(
        props.translations,
        hideSpinner,
        false,
        productsToBeAddedToCart
      );
    };

    const showApplePay = computed(() => {
      /** ApplePay API check */
      if (!isApplePayApiDefined()) {
        return false;
      }

      /** Out of Stock check */
      if (isSelectedProductOutOfStock.value) {
        return false;
      }

      /** Product custom check */
      return !(
        product.value?.customsRecipeID ||
        product.value?.recipe ||
        product.value?.recipeId
      );
    });

    return {
      ApplePayContext,
      initApplePaySession,
      pdpShowApplePayAsPrimaryCta,
      showApplePay,
      validateAndAddToCart,
    };
  },
});
