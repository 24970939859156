





























































































import { required } from 'vuelidate/lib/validators';
import { checkPassword } from '@vf/shared/src/utils/helpers';
import { email } from '@vf/shared/src/utils/helpers/validators';
import { defineComponent, ref, computed } from '@vue/composition-api';
import {
  useValidation,
  useNotification,
  useGtm,
  useAccount,
  useAuthentication,
  useCart,
  useFavorites,
} from '@vf/composables';
import SimplifiedEnrollmentHeader from './SimplifiedEnrollmentHeader.vue';
import useRootInstance from '@/shared/useRootInstance';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import type { Values as VueI18nValues } from 'vue-i18n';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';

export default defineComponent({
  name: 'VfSimplifiedEnrollmentPasswordIntake',
  components: {
    SimplifiedEnrollmentHeader,
  },
  props: {
    initialMail: {
      type: String,
      default: '',
    },
    isPartiallyEnrolled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { setValidation, $v } = useValidation(root, 'PASSWORD_INTAKE_FORM');
    const { displayErrorMessages } = errorMessages(root);
    const { clearNotifications } = useNotification(root);
    const { getBasicInformation } = useAccount(root);

    const t = (key: string, values?: VueI18nValues) =>
      root.$t(`simplifiedEnrollment.passwordIntake.${key}`, values) as string;

    const email = ref(props.initialMail);
    const password = ref(null);
    const isTermsConfirmed = ref(false);
    const isPrivacyConfirmed = ref(false);
    const isSubscribed = ref(false);
    const isLoading = ref(false);

    const { signUpSimplified } = useAuthentication(root);
    const { cartId, saveGuestCart, isCartMerged } = useCart(root);
    const {
      saveForLaterId,
      signIn,
      processPendingActionForLoyaltyUser,
    } = useAuthentication(root);
    const { favoriteId } = useFavorites(root);
    const { order } = useCheckoutStore();

    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation('modal-content');
    const { createSignIn, createSignUp } = useAuthenticationEvents(
      formLocation,
      {
        useLoadEvent: true,
        type: AuthenticationType.Registration,
      }
    );
    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });

    const boundWithGtmEventsSignUp = createSignUp(async () => {
      saveGuestCart();

      const campaignName = root.$route.query?.enrollment_marketing_source;

      const signUpPayload = {
        email: email.value,
        password: password.value,
        isSubscribed: isSubscribed.value,
        isTermsConfirmed: true,
        ...(campaignName ? { campaignName } : {}),
      };

      const signInPayload = {
        password: password.value,
        username: email.value,
        action: 'postLogin',
        guestObjects: {
          basketId: cartId.value,
          saveForLaterId: saveForLaterId.value,
          favoriteId: favoriteId.value,
        },
      };
      if (order) {
        signInPayload['st'] = order.st;
        signInPayload['orderNo'] = order.orderNumber;
      }

      try {
        isLoading.value = true;
        clearNotifications();

        await signUpSimplified(signUpPayload);
        const signInResponse = await boundWithGtmEventsSignIn(
          signInPayload,
          true
        );

        if (signInResponse) {
          await processPendingActionForLoyaltyUser();
          await getBasicInformation();
          isCartMerged.value = true;
          emit('submit-password');
        }
      } catch (e) {
        displayErrorMessages(e);
      } finally {
        isLoading.value = false;
      }
    });

    const onSubmit = async () => {
      $v.value.$touch();

      if ($v.value.$invalid) {
        return;
      }

      await boundWithGtmEventsSignUp();
    };

    const ctaLabel = computed(() => {
      return props.isPartiallyEnrolled ? t('partialUserCta') : t('newUserCta');
    });

    const termsLabel = t('termsLabel', {
      termsConditionsLink: root.localePath('/family/terms-conditions'),
      termsOfUseLink: root.localePath('/company/terms-of-use'),
    });

    return {
      email,
      password,
      isTermsConfirmed,
      isPrivacyConfirmed,
      isSubscribed,
      onSubmit,
      setValidation,
      isLoading,
      t,
      ctaLabel,
      termsLabel,
    };
  },

  mounted() {
    this.setValidation(this.$v);

    // handle link from retail enrollment welcome email
    if (!this.email && this.$route.query?.email) {
      const emailBase64Encoded = this.$route.query.email;
      const emailDecoded = atob(decodeURIComponent(emailBase64Encoded));
      this.email = emailDecoded;
    }
  },

  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
        checkPassword,
      },
      isTermsConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
      isPrivacyConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
    };
  },
});
