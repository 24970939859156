














































































































































































































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api';
import { required } from 'vuelidate/lib/validators';
import {
  useAccount,
  useFindInStore,
  useProduct,
  useRequestTracker,
  useValidation,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import ls from '@vf/composables/src/utils/localStorage';
import {
  PdpShippingMethod,
  useCartStore,
} from '@vf/composables/src/store/cartStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import type { BrandifyStoreInfo } from '@vf/api-client';

import VfShippingDestination from '@vf/ui/components/Atom.ShippingDestination.vue';

export default defineComponent({
  name: 'VfShippingDestinations',
  components: {
    VfShippingDestination,
    Toast: () => import('@/components/static/Toast.vue'),
  },
  props: {
    contextKey: {
      type: String,
      default: 'product',
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { onAllDone } = useRequestTracker(root);
    const {
      BRAND_ID_FIELD,
      getProductAvailabilities,
      getStores,
      requestPendingFlag,
      resetData,
      errorResponseDetails,
      stores: _,
    } = useFindInStore(root);
    const {
      checkAttributes,
      isQuickShopContext,
      product,
      scrollToFirstValidationError,
    } = useProduct(root, props.contextKey);
    const cart = useCartStore();
    const { setValidation, $v } = useValidation(root, 'SEARCH_STORE_FORM');
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();
    let isGeolocationUsed = false;

    const attributesNotValid = computed(() => checkAttributes({ lazy: true }));
    const { favoriteStoreId, setFavoriteStoreId } = useAccount(root);

    const QTY_THRESHOLD = 10;
    const LIMIT_OF_VISIBLE_STORES = 5;
    const RANGE_DISTANCE = '200';
    const storeDetailsId = ref(null);
    const search = ref('');
    const showSearchForm = ref(false);
    const searchApiErrorMessage = ref('');
    const apiNoStores = ref('');
    const selectedStore = ref(JSON.parse(ls.getItem('defaultStore')));
    const isProductAvailableInStore = ref(true);
    const storeId = ref(null);
    const availableToday = ref(false);
    const stores = ref([]);
    const showToast = ref(false);
    const refSearchInput = ref<{ $el: HTMLDivElement }>(null);

    const isSTS = ({ sts_enabled }: BrandifyStoreInfo) => sts_enabled === '1';
    const isBopis = ({ bopis_enabled, has_product }: BrandifyStoreInfo) =>
      has_product && bopis_enabled === '1';

    const checkProductAvailable = (store) => isBopis(store) || isSTS(store);

    const displaySearchForm = () => {
      showSearchForm.value = true;
      refSearchInput.value?.$el?.querySelector('input').focus();
    };

    const setStores = () => {
      if (availableToday.value) {
        const bopisStores = [];
        const stsStores = [];
        for (const item of _.value) {
          if (isBopis(item)) bopisStores.push(item);
          else if (isSTS(item)) stsStores.push(item);

          if (bopisStores.length === LIMIT_OF_VISIBLE_STORES) {
            stores.value = bopisStores;
            return;
          }
        }
        stores.value = bopisStores.concat(
          stsStores.slice(0, LIMIT_OF_VISIBLE_STORES - bopisStores.length)
        );
      } else {
        stores.value = _.value
          .filter(checkProductAvailable)
          .slice(0, LIMIT_OF_VISIBLE_STORES);
      }
    };

    const setStore = (store, background = false) => {
      if (!store || storeId.value === store[BRAND_ID_FIELD]) return;
      selectedStore.value = store;
      storeId.value = store[BRAND_ID_FIELD];
      isProductAvailableInStore.value = true;
      if (favoriteStoreId.value !== storeId.value) {
        setFavoriteStoreId(storeId.value);
      }
      ls.setItem('defaultStore', JSON.stringify(store));
      if (!background) showToast.value = true;
    };

    /**
     * return default Store if is present in stores
     * return first available store is we don't have default Store
     */
    const getPresetStore = () => {
      let findIndex = 0;
      if (selectedStore.value) {
        findIndex = stores.value.findIndex(
          (store) =>
            store[BRAND_ID_FIELD] === selectedStore.value[BRAND_ID_FIELD]
        );
      }
      return stores.value[findIndex];
    };

    const setErrorMessage = (response: null | unknown) => {
      const responseCode =
        errorResponseDetails.value?.response.data.errorDetails[0]?.code || 0;
      if (response === null && responseCode === 8009) {
        searchApiErrorMessage.value = root.$t(
          'shippingDestinations.locationNotRecognized'
        ) as string;
        $v.value.$touch();
      } else {
        apiNoStores.value = root.$t('shippingDestinations.noStores', {
          distance: RANGE_DISTANCE,
        }) as string;
      }
    };

    const resetErrorMessage = () => {
      searchApiErrorMessage.value = '';
      apiNoStores.value = '';
    };

    const scrollToError = () => {
      const {
        scrollToErrorOffsetWithTopStickyHeader,
        scrollToErrorOffset: scrollToErrorOffsetTheme,
      } = root.$themeConfig.productAddToCart;
      // TODO: GLOBAL15-63801 clean up
      const scrollToErrorOffset = !root.$viewport.isSmall
        ? scrollToErrorOffsetWithTopStickyHeader
        : scrollToErrorOffsetTheme;
      scrollToFirstValidationError(
        scrollToErrorOffset,
        root.$viewport.isSmall && isQuickShopContext.value
      );
    };

    const selectShipInStore = () => {
      if (attributesNotValid.value) {
        checkAttributes();
        scrollToError();
        return false;
      }
      cart.pdpShippingMethod = PdpShippingMethod.Pickup;
      return true;
    };

    const updateStores = async (params: Record<string, unknown>) => {
      storeDetailsId.value = null;
      cart.setCartLoading(true);
      const response = await getStores({
        ...params,
        productId: product.value.variant.id,
        enhancedStoreSearch: true,
        distance: RANGE_DISTANCE,
        unitOfMeasure: 'mile',
        sortByAvailability: availableToday.value,
        resultSetSize: LIMIT_OF_VISIBLE_STORES,
      });
      setStores();
      if (stores.value.length) {
        setStore(getPresetStore(), true);
      } else {
        displaySearchForm();
        setErrorMessage(response);
      }

      cart.setCartLoading(false);
    };

    const changeStore = () => {
      if (!selectShipInStore()) return;
      if (isGeolocationUsed) {
        displaySearchForm();
        return;
      }
      cart.setCartLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          isGeolocationUsed = true;
          resetErrorMessage();
          await updateStores({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          if (!selectedStore.value) {
            displaySearchForm();
          }
        },
        () => {
          displaySearchForm();
          cart.setCartLoading(false);
        }
      );
    };

    const searchByPC = async () => {
      $v.value.$touch();
      resetErrorMessage();

      if ($v.value.$invalid) {
        resetData();
        refSearchInput.value.$el.querySelector('input').focus();
        return;
      }
      await updateStores({ postalCode: search.value });
    };

    const changeAvailability = async () => {
      if (_.value.length === 0) return;
      try {
        cart.setCartLoading(true);
        const availabilityResponse = await getProductAvailabilities(
          _.value,
          product.value.variant.id,
          {
            sortByAvailability: availableToday.value,
            resultSetSize: LIMIT_OF_VISIBLE_STORES,
          }
        );
        (availabilityResponse.data.storeInventory || []).forEach(
          ({ storeId, quantity = 0 }) => {
            const store = _.value.find(
              (item) => item[BRAND_ID_FIELD] === storeId
            );
            if (!store) return;
            store.has_product = quantity > 0;
            store.quantity = quantity;
          }
        );
        setStores();
        if (selectedStore.value)
          isProductAvailableInStore.value = checkProductAvailable(
            selectedStore.value
          );
      } catch (err) {
        root.$log.error(
          `[[@theme/components/static/pdp/ShippingDestinations::changeAvailability]: Failed getProductAvailabilities of productId: ${product.value.variant.id}`,
          err
        );
      } finally {
        cart.setCartLoading(false);
      }
    };

    const getVariantId = () => {
      if (product.value?.variant) {
        for (const attrKey in product.value.variant.attributes) {
          if (
            product.value[attrKey]?.value &&
            product.value.variant.attributes[attrKey] !==
              product.value[attrKey]?.value
          )
            return;
        }
        return product.value.variant.id;
      }
    };

    const availabilityCheck = async (productId?: string) => {
      if (productId) {
        try {
          const availabilityResponse = await getProductAvailabilities(
            [selectedStore.value],
            productId
          );
          const quantity =
            availabilityResponse.data.storeInventory?.[0].quantity || 0;
          selectedStore.value.has_product = quantity > 0;
          selectedStore.value.quantity = quantity;
          isProductAvailableInStore.value = checkProductAvailable(
            selectedStore.value
          );
        } catch (err) {
          isProductAvailableInStore.value = false;
          root.$log.error(
            `[[@theme/components/static/pdp/ShippingDestinations::availabilityCheck]: Failed getProductAvailabilities of productId: ${productId}`,
            err
          );
        }
      }
    };

    let unwatchAvailabilityCheck = () => null;

    onAllDone(() => {
      unwatchAvailabilityCheck = watch(
        [attributesNotValid, () => product.value?.variant?.id],
        async () => {
          if (
            !selectedStore.value ||
            attributesNotValid.value ||
            !props.visible
          )
            return;
          const variantId = getVariantId();
          if (!variantId) return;

          if (showSearchForm.value) {
            search.value = search.value || selectedStore.value?.postalcode;
            await searchByPC();
          }
          await availabilityCheck(variantId);
        },
        {
          immediate: true,
        }
      );
    });

    onBeforeUnmount(() => {
      unwatchAvailabilityCheck();
    });

    const apiValidator = () => searchApiErrorMessage.value === '';

    return {
      apiNoStores,
      apiValidator,
      BRAND_ID_FIELD,
      changeAvailability,
      searchApiErrorMessage,
      attributesNotValid,
      searchByPC,
      selectShipInStore,
      changeStore,
      setStore,
      cart,
      isBopis,
      isCoreRedesignEnabled,
      isProductAvailableInStore,
      isSTS,
      LIMIT_OF_VISIBLE_STORES,
      RANGE_DISTANCE,
      showToast,
      storeDetailsId,
      requestPendingFlag,
      availableToday,
      QTY_THRESHOLD,
      refSearchInput,
      stores,
      storeId,
      selectedStore,
      setValidation,
      search,
      showSearchForm,
      PdpShippingMethod,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations() {
    return {
      search: {
        required,
        apiValidator: this.apiValidator,
      },
    };
  },
});
