
































































































































































import type { PropType } from 'vue';
import { computed, ref, defineComponent, nextTick } from '@vue/composition-api';
import type { ProductActionButtonsTranslations } from '@vf/api-contract';
import { storeToRefs } from 'pinia';
import {
  useProduct,
  useFindInStore,
  useNotification,
  useGtm,
  useSignInToStore,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import SaveToFavorites from '@/components/smart/pdp/SaveToFavorites.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
export default defineComponent({
  name: 'ProductActionButtonsPDP',
  components: {
    SaveToFavorites,
  },
  props: {
    largeButton: Boolean,
    translations: {
      type: Object as PropType<ProductActionButtonsTranslations>,
      default: () => ({}),
    },
    /** Flag to determine if FE should render Add to favorites button */
    turnOnAddToFavourites: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if FE should render just an heart */
    turnOnAddToFavouritesHeart: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if FE should render Find in store button */
    turnOnFindInStore: {
      type: Boolean,
      default: true,
    },
    /** Display variant of buttons (Not yet used) */
    buttonsVariant: {
      type: String,
      validator: (value: string) => {
        return ['text-links', 'buttons'].includes(value);
      },
      default: 'text-links',
    },
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  emits: ['click-find-in-store', 'close-find-in-store-modal'],
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.productActionButtons;
    const {
      isBopis20Enabled,
      isVansPdpRedesignEnabled,
    } = useFeatureFlagsStore(); // TODO: cleanup after GLOBAL15-63801
    const { toggleFavorites, isFavorite, loading } = useFavoritesWithDataLayer(
      PageTypeName.PDP
    );

    const {
      product,
      findInStoreQuickShopVisible,
      isQuickShopContext,
      isSelectedProductOutOfStock,
      scrollToFirstValidationError,
      checkAttributes,
      attributesData,
    } = useProduct(root, props.contextKey);
    const { validateSize, resetData, scrollToFindInStoreForm } = useFindInStore(
      root
    );
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { employeeConnected } = useSignInToStore(root);
    const { openModal } = useModal();
    const { dispatchEvent } = useGtm(root);
    const { clearNotifications, addNotification } = useNotification(root);
    const showModalDeliveryDetails = ref(false);
    const favoritesText = computed(() => {
      return isFavorite(product.value.id)
        ? props.translations.removeFromFavorites
        : props.translations.addToFavorites;
    });
    const productColor = computed(() =>
      product.value !== null ? product.value.color?.value : null
    );

    const findInStoreHidden = computed<boolean>(
      () => !props.turnOnFindInStore || product.value?.dummyCustoms
    );

    const disabledFindInStoreButton = computed(
      () =>
        isSelectedProductOutOfStock.value === null ||
        isSelectedProductOutOfStock.value ||
        attributesData.value.isFutureProduct
    );

    const wrapperPaddings = computed(
      () =>
        theme.wrapperPaddings[props.contextKey] || theme.wrapperPaddings.default
    );

    const findInStoreModal = () => {
      if (disabledFindInStoreButton.value) return;
      const hasAttributesError = checkAttributes();
      const hasValidSize = validateSize(product.value);
      if (hasAttributesError || !hasValidSize) {
        // TODO: GLOBAL15-63801 clean up
        const scrollToErrorOffset = !root.$viewport.isSmall
          ? theme.scrollToErrorOffsetWithTopStickyHeader
          : theme.scrollToErrorOffset;
        scrollToFirstValidationError(scrollToErrorOffset, false);
        return;
      }
      resetData();
      if (!isQuickShopContext.value && !isSelectedProductOutOfStock.value) {
        openModal({
          type: 'fragment',
          resourceId: 'find-in-store-modal',
          contextKey: props.contextKey,
        });
      } else {
        findInStoreQuickShopVisible.value = true;
        nextTick(() => scrollToFindInStoreForm());
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Click on Find in Store',
          eventLabel: undefined,
        },
      });
    };

    const closeModalAction = () => {
      resetData();
    };

    const slotChangeIndicator = ref(new Date());
    const triggerSlotChange = () => {
      slotChangeIndicator.value = new Date();
    };

    const toggleFavoritesHandler = async (product) => {
      clearNotifications();

      const isAdded = await toggleFavorites(product);

      if (isAdded === true) {
        const message = props.translations.addedToFavoritesNotification?.replace(
          '{{product}}',
          product.name
        );

        addNotification({
          errorMessageId: null,
          message,
          type: 'success',
        });
      }

      return isAdded;
    };

    // Do not show if employee is connected and config is true.
    const shouldShowAddToFavButton = computed(
      () =>
        !(
          (employeeConnected.value &&
            root.$themeConfig?.saveToFavorites?.hideFavouriteCtaForEmployees) ??
          false
        )
    );

    const modalResourceId = computed(
      () =>
        props.modals[
          root.$themeConfig.productAddToCart.modal?.[
            loggedIn.value ? 'loggedIn' : 'notLoggedIn'
          ] || 'loyaltyEnrollment'
        ]
    );

    const openSignInModal = async () => {
      await nextTick();
      openModal({
        type: 'lazyFragment',
        resourceId: modalResourceId.value,
      });
    };

    return {
      theme,
      product,
      productColor,
      favoritesText,
      addToFavoritesLoading: loading,
      findInStoreModal,
      findInStoreHidden,
      disabledFindInStoreButton,
      closeModalAction,
      slotChangeIndicator,
      triggerSlotChange,
      isQuickShopContext,
      toggleFavoritesHandler,
      isFavorite,
      wrapperPaddings,
      shouldShowAddToFavButton,
      showModalDeliveryDetails,
      isBopis20Enabled,
      isVansPdpRedesignEnabled,
      openSignInModal,
    };
  },
});
