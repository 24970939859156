




























































import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  watch,
} from '@vue/composition-api';
import { CartShippingGroup } from '@vf/api-client';
import { useCart, useRequestTracker } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutShippingGroup',
  components: {
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
  },
  props: {
    group: {
      type: Object as PropType<CartShippingGroup>,
      required: true,
    },
    groupDisabled: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { getShippingMethods, getShippingGroupFormattedPrice } = useCart(
      root
    );
    const cartStore = useCartStore();
    const { checkoutSimplifiedAddress } = useFeatureFlagsStore();
    const { isRequestOngoing, getOngoingRequest } = useRequestTracker(root);
    const { hasPoAddress } = storeToRefs(cartStore);
    const { contextKey, dynamicSlots } = inject('dynamicSlots');

    const isCustoms = computed(() => props.group.code.startsWith('customs'));

    const slot = computed(() => {
      const slotName = isCustoms.value
        ? 'aboveCustomsShippingOptions'
        : 'aboveShippingOptions';
      return dynamicSlots.value[slotName];
    });

    const addressChangePrompter = computed(() => {
      return JSON.stringify(props.group.address);
    });

    const changeShippingMethod = (method) => {
      if (props.group.code === method.code) return;
      emit('change', method);
    };

    onMounted(() => {
      watch(
        addressChangePrompter,
        async (newVal, oldVal) => {
          if (newVal !== oldVal && props.group.shippingId) {
            if (isRequestOngoing('checkout:set-checkout-address')) {
              await getOngoingRequest('checkout:set-checkout-address');
            }
            getShippingMethods(props.group.shippingId, {
              isBackgroundRequest: false,
              isTemporary: false,
            });
          }
        },
        { immediate: true }
      );
    });

    const groupTranslations = computed(() => {
      const groupId = isCustoms.value ? 'customs' : 'me';
      return root.$t(`checkoutShippingGroup.${groupId}`) || {};
    });

    return {
      getShippingGroupFormattedPrice,
      changeShippingMethod,
      hasPoAddress,
      groupTranslations,
      contextKey,
      slot,
      checkoutSimplifiedAddress,
    };
  },
});
