



































import { computed, defineComponent, inject } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import {
  useCart,
  useProduct,
  useGtm,
  useFavorites,
  useNotification,
  useNotifyMe,
} from '@vf/composables';
import { addVariantOptionToUrlParams, mapValueOrFallback } from '@/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'SaveToFavorites',
  props: {
    heartButton: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      required: true,
      default: 'add to favorites',
    },
    addText: {
      type: String,
      default: 'Add to Favorites',
    },
    removeText: {
      type: String,
      default: 'Remove from Favorites',
    },
    addedText: {
      type: String,
      default: 'Added to Favorites',
    },
    contextKey: {
      type: String,
      default: 'product',
    },
    addedToFavoritesNotification: {
      type: String,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { isAddItemRequestPending } = useCart(root);
    const { dispatchEvent } = useGtm(root);
    const {
      product,
      isSelectedProductOutOfStock,
      checkAttributes,
      scrollToFirstValidationError,
      areAllAttributesSelected,
      isAttributeOutOfStockStatus,
    } = useProduct(root, props.contextKey);
    const { isUnavailableSizeHovered } = useNotifyMe(root, props.contextKey);
    const {
      addToFavorites,
      favoriteId,
      makeImageVariantUrlIdentifierByProduct,
      showFavoritesTooltip,
      loading,
    } = useFavorites(root);
    const { clearNotifications, addNotification } = useNotification(root);
    const { toggleFavorites, isFavorite } = useFavoritesWithDataLayer(
      PageTypeName.PDP
    );
    const isNotifyMeEnabledFeatureFlag = useFeatureFlagsStore()
      .isNotifyMeEnabled;

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const theme = root.$themeConfig.saveToFavorites;

    const productId = computed(() => {
      return theme.fullyConfiguredProductForFavorites
        ? product.value?.sku
        : product.value?.id;
    });

    const favoritesIconName = computed(() => {
      if (theme.fullyConfiguredProductForFavorites) {
        return areAllAttributesSelected.value && isFavorite(productId.value)
          ? 'heart__fill'
          : 'heart';
      }
      return isFavorite(productId.value) ? 'heart__fill' : 'heart';
    });

    const favoritesText = computed(() => {
      if (isFavorite(productId.value)) {
        return theme.toggleableFavorites ? props.removeText : props.addedText;
      }

      return props.addText;
    });

    const scrollToError = () => {
      if (theme.scrollToError) {
        const {
          scrollToErrorOffsetWithTopStickyHeader,
          scrollToErrorOffset: scrollToErrorOffsetTheme,
        } = theme;
        // TODO: GLOBAL15-63801 clean up
        const scrollToErrorOffset = !root.$viewport.isSmall
          ? scrollToErrorOffsetWithTopStickyHeader
          : scrollToErrorOffsetTheme;
        scrollToFirstValidationError(scrollToErrorOffset);
      }
    };

    const productColor = computed(() => product.value?.color?.value);

    const isNotifyMeEnabled = computed(
      () =>
        isNotifyMeEnabledFeatureFlag &&
        (isSelectedProductOutOfStock.value || isUnavailableSizeHovered.value) &&
        product.value?.notifyMe
    );

    const isButtonDeactivated = computed(() => {
      return (
        loading.value ||
        (!isNotifyMeEnabled.value &&
          [
            isSelectedProductOutOfStock.value,
            !productColor.value,
            isAttributeOutOfStockStatus('size', product.value?.size?.value),
            isAttributeOutOfStockStatus('length', product.value?.length?.value),
            isAddItemRequestPending.value,
            favoritesText.value === props.addedText,
          ].some(Boolean))
      );
    });

    const onSuccessfulAddToFavorites = (success) => {
      if (success) {
        showFavoritesTooltip(10000);
        dispatchEvent(
          getEventFromTemplate('favorite:add', {
            eventCategory: PageTypeName.PDP,
            eventLabel: `${product.value.id} - ${product.value.colorDescription}`,
          })
        );
      }
    };

    const validateAndAddToFavorites = async () => {
      if (checkAttributes()) {
        scrollToError();
        return;
      }
      if (!isFavorite(productId.value)) {
        let productUrl = product.value.pageUrl;
        const selectedVariants = Object.entries(
          product.value.variant.attributes
        );
        selectedVariants.forEach((variant) => {
          productUrl = addVariantOptionToUrlParams(
            root,
            variant[0].toString(),
            variant[1].toString(),
            productUrl
          );
        });

        const imageUrlIdentifier = makeImageVariantUrlIdentifierByProduct(
          product.value
        );

        const addToFavoritesPayload = mapValueOrFallback(
          {
            favoriteId: favoriteId.value,
            recipeId: product.value.recipeId,
            id: product.value.variant.id,
            itemType: 'product',
            pdpUrl: productUrl,
            pageUrl: productUrl,
            productImageUrl: root.$mediaUrlGenerator({
              colorName: product.value.color?.label,
              pid: imageUrlIdentifier,
              productName: product.value.name,
            }) as string,
            available: product.value.available,
            qty: 1,
            defaultProductView: '',
            public: true,
          },
          { favoriteId: '', recipeId: '', available: '' }
        );

        const success = await addToFavorites(addToFavoritesPayload);
        onSuccessfulAddToFavorites(success);
      }
    };

    const handleToggleFavorites = async (product) => {
      clearNotifications();

      const isAdded = await toggleFavorites(product);

      if (isAdded) {
        const message = props.addedToFavoritesNotification?.replace(
          '{{product}}',
          product.name
        );

        addNotification({
          errorMessageId: null,
          message,
          type: 'success',
        });
      }
    };

    const onFavoriteClick = async (product) => {
      if (
        theme.toggleableFavorites &&
        !theme.fullyConfiguredProductForFavorites
      ) {
        await handleToggleFavorites(product);
        return;
      }
      await validateAndAddToFavorites();
    };

    const heartButtonActive = computed(() => isFavorite(productId.value));

    return {
      isButtonDeactivated,
      product,
      isNotifyMeEnabled,
      validateAndAddToFavorites,
      favoritesIconName,
      favoritesText,
      onFavoriteClick,
      heartButtonActive,
      isCoreRedesignEnabled,
    };
  },
});
